"use client"
import GuestLayout from "@/layouts/GuestLayout"
import Link from "next/link"

export default function NotFound(params) {
  return (<>
    <section className="mainBackground">
      <GuestLayout>
        <main className={`text-center d-flex flex-column align-items-center justify-content-center mh-90`}>

          <h2>404 | Not Found </h2>
          <p>
            Go to home <Link href="/">home</Link>
          </p>
        </main>
      </GuestLayout>
    </section>
  </>
  )
}